export const ATTENDEES_MESSAGE_RECIPIENT_TYPE = {
  ALL_ATTENDEES: { label: 'All Attendees', value: 'ALL_ATTENDEES' },
  ATTENDEES_BY_TICKET_CATEGORY: {
    label: 'Attendees by Ticket Category',
    value: 'ATTENDEES_BY_TICKET_CATEGORY',
  },
};

export const ATTENDEES_MESSAGE_STATUS = Object.freeze({
  SENT: { label: 'Sent', value: 'SEND' },
  DRAFT: { label: 'Draft', value: 'DRAFT' },
});

export const EVENT_VISIBILITY_STATUS = Object.freeze({
  PUBLISHED: { label: 'Published', value: 'PUBLISHED' },
  SCHEDULED: { label: 'Scheduled', value: 'SCHEDULED' },
  DRAFT: { label: 'Draft', value: 'DRAFT' },
});

export const TICKET_REFUND_POLICY = Object.freeze({
  NO_REFUND: {
    label: 'Non Refundable',
    value: 'false',
  },
  ONE_DAY: {
    label: 'Refundable',
    value: 'true',
  },
  // TWO_DAYS: {
  //   label: 'Refund Available 2 days before the event',
  //   value: 2,
  // },

  // THREE_DAYS: {
  //   label: 'Refund Available 3 days before the event',
  //   value: 3,
  // },

  // FIVE_DAYS: {
  //   label: 'Refund Available 5 days before the event',
  //   value: 5,
  // },
  // SEVEN_DAYS: {
  //   label: 'Refund Available 7 days before the event',
  //   value: 7,
  // },

  // TEN_DAYS: {
  //   label: 'Refund Available 10 days before the event',
  //   value: 10,
  // },

  // FIFTEEN_DAYS: {
  //   label: 'Refund Available 15 days before the event',
  //   value: 15,
  // },

  // THIRTY_DAYS: {
  //   label: 'Refund Available 30 days before the event',
  //   value: 30,
  // },
});

export const TICKET_TYPE = Object.freeze({
  PAID: { label: 'Paid', value: 'PAID' },
  FREE: { label: 'Free', value: 'FREE' },
  // DONATION: { label: 'Donation', value: 'DONATION' },
});

export const TICKET_DELIVERY_METHOD = Object.freeze({
  VIRTUAL: { label: 'Virtual', value: 'VIRTUAL' },
  PHYSICAL: { label: 'Physical', value: 'PHYSICAL' },
});

export const NEW_PROMOTER_ACTION = Object.freeze({
  APPROVED: { label: 'Approved', value: 'APPROVED' },
  DECLINE: { label: 'Decline', value: 'DECLINE' },
});

export const PromoterSuccessType = Object.freeze({
  showAllEvents: {
    label: 'Allow events from this host on my subdomain automatically',
    value: false,
  },
  chooseEventsManually: { label: 'I will choose events manually', value: true },
});

export const EVENT_TYPE = Object.freeze({
  PHYSICAL: { label: 'Address', value: 'PHYSICAL' },
  VIRTUAL: { label: 'This is a virtual event', value: 'VIRTUAL' },
});

export const EVENT_OCCURRENCE = Object.freeze({
  ONCE: { label: 'One Time', value: 1 },
  DAILY: { label: 'Daily', value: 2 },
  WEEKLY: { label: 'Weekly', value: 5 },
  FORTNIGHTLY: { label: 'Forthnightly', value: 3 },
  MONTHLY: { label: 'Monthly', value: 4 },
  // YEARLY: { label: 'Yearly', value: 'YEARLY' },
});

export const EVENT_STATUS = Object.freeze({
  NEW: { label: 'New', value: 'NEW', color: '#039661' },
  SOLD_OUT: { label: 'Sold Out', value: 'SOLD_OUT', color: '#b00020' },
});

export const HTTP_REQ_STATUS = Object.freeze({
  idle: 'idle',
  error: 'error',
  success: 'success',
  loading: 'loading',
});

export const AUTH_VIEW = Object.freeze({
  SIGNIN: {
    label: 'Sign In',
    value: 'SIGNIN',
    path: '/signin',
  },
  SIGNUP: {
    label: 'Sign up',
    value: 'SIGNUP',
    path: '/register',
    hide: true,
  },
  FORGOT_PASSWORD: {
    label: 'Reset Password',
    value: 'FORGOT_PASSWORD',
    hide: true,
  },
});

export const USER_TYPE = Object.freeze({
  INDIVIDUAL: { label: 'Individual', value: 'Individual' },
  BUSINESS: { label: 'Organisation', value: 'Organisation' },
});

export const ACCOUNT_TYPE = Object.freeze({
  BASIC: { label: 'Basic', value: 'Basic' },
});

export const VIEWPORT_BREAKPOINTS = Object.freeze({
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  big: 1824,
  portrait: '(orientation: portrait)',
  highResolution: '(min-resolution: 2dppx)',
});

export const PERSONAL_INFO_TYPE = Object.freeze({
  INDIVIDUAL: { label: 'Individual', value: 'INDIVIDUAL' },
  ORGANISATION: { label: 'Organisation', value: 'ORGANISATION' },
});

export const ENTITY_ACTIONS = Object.freeze({
  NO_OP: 'NO_OP',
  CREATE: 'CREATE',
  TICKET_DELIVERY_DIALOG: 'TICKET_DELIVERY_DIALOG',
  TICKET_DELIVERY_DIALOG_CLOSE: 'TICKET_DELIVERY_DIALOG_CLOSE',
  DOWNLOAD_DIALOG_TXIS: 'DOWNLOAD_DIALOG_TXIS',
  DOWNLOAD_TXIS_DIALOG_CLOSE: 'DOWNLOAD_TXIS_DIALOG_CLOSE',
  EXTERNAL_EVENT_DIALOG: 'EXTERNAL_EVENT_DIALOG',
  EXTERNAL_EVENT_DIALOG_CLOSE: 'EXTERNAL_EVENT_DIALOG_CLOSE',
  CHANGE_SEATS: 'CHANGE_SEATS',
  CHANGE_SEATS_CLOSE: 'CHANGE_SEATS_CLOSE',
  VIEW_ALL_SEATS: 'VIEW_ALL_SEATS',
  VIEW_ALL_SEATS_CLOSE: 'VIEW_ALL_SEATS_CLOSE',
  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_EVENT_CLOSE: 'DELETE_EVENT_CLOSE',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_CLOSE: 'DELETE_ACCOUNT_CLOSE',
  IMPORTANT_INFORMATION_TICKET_TRANSFER:
    'IMPORTANT_INFORMATION_TICKET_TRANSFER',
  IMPORTANT_INFORMATION_TICKET_TRANSFER_CLOSE:
    'IMPORTANT_INFORMATION_TICKET_TRANSFER_CLOSE',
  EMAIL_TICKET_TRANSFER: 'EMAIL_TICKET_TRANSFER',
  EMAIL_TICKET_TRANSFER_CLOSE: 'EMAIL_TICKET_TRANSFER_CLOSE',
  SUCCESS_TRANSFER_TIKCET: 'SUCCESS_TRANSFER_TIKCET',
  SUCCESS_TRANSFER_TIKCET_CLOSE: 'SUCCESS_TRANSFER_TIKCET_CLOSE',
  TRANSFER_TICKET_ACCEPT: 'TRANSFER_TICKET_ACCEPT',
  TRANSFER_TICKET_ACCEPT_CLOSE: 'TRANSFER_TICKET_ACCEPT_CLOSE',
  TRANSFER_TICKET_ACCEPT_SUCCESS: 'TRANSFER_TICKET_ACCEPT_SUCCESS',
  TRANSFER_TICKET_ACCEPT_SUCCESS_CLOSE: 'TRANSFER_TICKET_ACCEPT_SUCCESS',
  TRANSFER_TICKET_DECLINE: 'TRANSFER_TICKET_DECLINE',
  TRANSFER_TICKET_DECLINE_CLOSE: 'TRANSFER_TICKET_DECLINE_CLOSE',
  TRANSFER_TICKET_DECLINE_SUCCESS: 'TRANSFER_TICKET_DECLINE_SUCCESS',
  TRANSFER_TICKET_DECLINE_SUCCESS_CLOSE:
    'TRANSFER_TICKET_DECLINE_SUCCESS_CLOSE',
  TRANSFER_TICKET_RECALL: 'TRANSFER_TICKET_RECALL',
  TRANSFER_TICKET_RECALL_CLOSE: 'TRANSFER_TICKET_RECALL_CLOSE',
  BANK_ACCOUNT_DIALOG: 'BANK_ACCOUNT_DIALOG',
  BANK_ACCOUNT_DIALOG_CLOSE: 'BANK_ACCOUNT_DIALOG_CLOSE',
  HOST_TICKET_BUY_DETAIL: 'HOST_TICKET_BUY_DETAIL',
  HOST_TICKET_BUY_DETAIL_CLOSE: 'HOST_TICKET_BUY_DETAIL_CLOSE',
});

export const transactionsStatus = Object.freeze({
  AWAITINGPAYMENTCONFIRMATION: 'AWAITINGPAYMENTCONFIRMATION',
  UNDERREVIEW: 'UNDERREVIEW',
  INPROGRESS: 'INPROGRESS',
  UNRESOLVED: 'UNRESOLVED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  SUCCESSFUL: 'SUCCESSFUL',
  INSTALLMENTINPROGRESS: 'INSTALLMENTINPROGRESS',
  ABORTED: 'ABORTED',
  REFUNDED: 'REFUNDED',
  BARCODEPENDING: 'BARCODEPENDING',
  PROCESSED: 'PROCESSED',
  TIMEDOUT: 'TIMEDOUT',
});

export const BANKING_INFO_TYPE = Object.freeze({
  CHECKING: { label: 'Checking', value: 'CHECKING' },
  SAVINGS: { label: 'Savings', value: 'SAVINGS' },
});

export const DASHBOARD_EVENT_STATUS = Object.freeze({
  PUBLISHED: { label: 'Published', value: 'PUBLISHED' },
  DRAFT: { label: 'Draft', value: 'DRAFT' },
  EXPIRED: { label: 'Expired', value: 'EXPIRED' },
});

export const SELECT_CRITERIA_BOOL_OPTIONS = Object.freeze({
  YES: { label: 'Yes', value: 'YES' },
  NO: { label: 'No', value: 'NO' },
});

export const APPROVAL_TYPE = Object.freeze({
  MANUALAPPROVAL: 1,
  AUTOAPPROVE: 2,
  NOTREQUIRED: 3,
});

export const NOTIFICATION_TYPE = Object.freeze({
  INVOICE: 1,
  PROMOTIONAVAILABLE: 2,
  EVENTTICKETSALE: 3,
});

export const FOLLOW_HOST_PROMOTER_REQUEST = Object.freeze({
  MANUAL: { label: 'Manual Approval', value: 'MANUAL' },
  AUTO: { label: 'Auto Approval', value: 'AUTO' },
});

export const FOLLOW_HOST_PROMOTER_CHOICE = Object.freeze({
  MANUAL: { label: 'Manual Approval', value: 1 },
  AUTO: { label: 'Auto Approval', value: 2 },
});

export const PROMOTER_REQUEST_STATUS = {
  1: 'Approved',
  2: 'Awaiting Approval',
  3: 'Declined',
};

export const PROMOTER_REQUEST_STATUS_INTENT = {
  1: 'success',
  2: 'warning',
  3: 'danger',
};

export const APPROVED_DECLINE = Object.freeze({
  APPROVED: { label: 'Approve', value: 'APPROVED' },
  DECLINE: { label: 'Decline', value: 'DECLINE' },
});

export const APPROVE_DECLINE_PROMOTER_REQUEST = Object.freeze({
  APPROVE: { label: 'Approve', value: true },
  DECLINE: { label: 'Decline', value: false },
});

export const DRESS_CODE = Object.freeze({
  '': { label: 'Select dress code', value: '' },
  NOT_APPLICABLE: { label: 'Not Applicable', value: 'NOT_APPLICABLE' },
  SMART: { label: 'Smart', value: 'SMART' },
  BLACK_TIE: { label: 'Black Tie', value: 'BLACK_TIE' },
  CASUAL: { label: 'Casual', value: 'CASUAL' },
  FANCY_DRESS: { label: 'Fancy Dress', value: 'FANCY_DRESS' },
  NATIVE: { label: 'Native', value: 'NATIVE' },
});

export const EVENT_EXCLUSIONS = Object.freeze({
  NO_EXCLUSION: { label: 'No Exclusion', value: 'NONE' },
  ALL_SATURDAYS: { label: 'Exclude All Saturdays', value: 'ALL_SATURDAYS' },
  ALL_SUNDAYS: { label: 'Exclude All Sundays', value: 'ALL_SUNDAYS' },
  ALL_SATURDAYS_AND_SUNDAYS: {
    label: 'Exclude All Saturdays & Sundays',
    value: 'ALL_SATURDAYS_AND_SUNDAYS',
  },
});

export const CURRENCY_SYMBOLS = Object.freeze({
  USD: '$',
  GBP: '£',
  NGN: 'N',
  EUR: '€',
});

export const IS_EVENT_PUBLIC = Object.freeze({
  PUBLIC: { label: 'Public', value: true },
  UNLISTED: { label: 'Unlisted', value: false },
});

export const BOOLEAN_OPTIONS = Object.freeze({
  TRUE: { label: 'Yes', value: 'true' },
  FALSE: { label: 'No', value: 'false' },
});

export const DONATION_TYPE = Object.freeze({
  FIXED: { label: 'Fixed Amount', value: 'FIXED' },
  MINIMUM: { label: 'Minimum Amount', value: 'MIN' },
});

export const SEATING_TYPE = Object.freeze({
  STANDING: { label: 'Standing', value: false },
  SEATING: { label: 'Seating', value: true },
});

export const DATE_FILTERS = Object.freeze({
  ALL: { label: 'All', value: 'ALL' },
  PAST_7: { label: '7 days', value: '7' },
  PAST_30: { label: '30 days', value: '30' },
  PAST_YEAR: { label: 'Past year', value: 'PAST YEAR' },
  // CUSTOM: { label: 'Custom', value: 'CUSTOM' },
});

export const defaultFormSelectOption = Object.freeze({
  label: 'None',
  value: '',
});

export const paymentStatusIntent = {
  ABORTED: 'disabled',
  SUCCESS: 'success',
  FAILED: 'danger',
  NONE: 'none',
  REFUNDED: '#ED55C2',
  INPR0GRESS: 'warning',
};

export const transactionStatusIntent = {
  AWAITINGFURTHERCHECK: 'disabled',
  BARCODEPENDING: 'warning',
  AWAITINGPROCESS: 'warning',
  UNDERREVIEW: 'warning',
  UNRESOLVED: 'warning',
  INPROGRESS: 'warning',
  PROCESSED: 'success',
  CANCELLED: 'danger',
  FAILED: 'danger',
  ABORTED: 'danger',
  TIMEDOUT: 'danger',
  INPR0GRESS: 'warning',
  REFUNDED: 'danger',
};

export const COUNTRY_SUPPORTING_LIST_STRIPE_PAY_BUTTON = Object.freeze({
  AE: 'AE', // United Arab Emirates
  AT: 'AT', // Austria
  AU: 'AU', // Australia
  BE: 'BE', // Belgium
  BG: 'BG', // Bulgaria
  BR: 'BR', // Brazil
  CA: 'CA', // Canada
  CH: 'CH', // Switzerland
  CI: 'CI', // Côte d'Ivoire (Ivory Coast)
  CR: 'CR', // Costa Rica
  CY: 'CY', // Cyprus
  CZ: 'CZ', // Czech Republic
  DE: 'DE', // Germany
  DK: 'DK', // Denmark
  DO: 'DO', // Dominican Republic
  EE: 'EE', // Estonia
  ES: 'ES', // Spain
  FI: 'FI', // Finland
  FR: 'FR', // France
  GB: 'GB', // United Kingdom
  GI: 'GI', // Gibraltar
  GR: 'GR', // Greece
  GT: 'GT', // Guatemala
  HK: 'HK', // Hong Kong
  HR: 'HR', // Croatia
  HU: 'HU', // Hungary
  ID: 'ID', // Indonesia
  IE: 'IE', // Ireland
  IN: 'IN', // India
  IT: 'IT', // Italy
  JP: 'JP', // Japan
  LI: 'LI', // Liechtenstein
  LT: 'LT', // Lithuania
  LU: 'LU', // Luxembourg
  LV: 'LV', // Latvia
  MT: 'MT', // Malta
  MX: 'MX', // Mexico
  MY: 'MY', // Malaysia
  NL: 'NL', // Netherlands
  NO: 'NO', // Norway
  NZ: 'NZ', // New Zealand
  PE: 'PE', // Peru
  PH: 'PH', // Philippines
  PL: 'PL', // Poland
  PT: 'PT', // Portugal
  RO: 'RO', // Romania
  SE: 'SE', // Sweden
  SG: 'SG', // Singapore
  SI: 'SI', // Slovenia
  SK: 'SK', // Slovakia
  SN: 'SN', // Senegal
  TH: 'TH', // Thailand
  TT: 'TT', // Trinidad and Tobago
  US: 'US', // United States
  UY: 'UY', // Uruguay
});

export const FIREPAY_PAYMENT_STATUS = Object.freeze({
  AWAITING_AUTHORISATION: 'AWAITING_AUTHORISATION',
  AUTHORISED: 'AUTHORISED',
  AWAITING_MULTI_AUTHORISATION: 'AWAITING_MULTI_AUTHORISATION',
  NOT_AUTHORISED: 'NOT_AUTHORISED',
  PENDING: 'PENDING',
  PAYMNET_CONFIRMED: 'PAYMNET_CONFIRMED',
  PAID: 'PAID',
});

export const EVENT_STATUS_ID = Object.freeze({
  DRAFT: 1,
  PUBLISHED: 2,
  UNPUBLISHED: 3,
  SCHEDULED: 4,
  PAST: 5,
  CANCELLED: 6,
  MODIFIED: 7,
});

export const LOGIN_ATTEMPTS = Object.freeze({
  FIST_ATTEMPT: '1',
  SECOND_ATTEMPT: '2',
});

export const ENQUIRY_STATUS = Object.freeze({
  ALL: { id: 0, description: 'All', shortcode: 'ALL' },
  OPEN: { id: 1, description: 'New', shortcode: 'NEW' },
  REPLIED: { id: 2, description: 'Open', shortcode: 'OPEN' },
  RESOLVED: { id: 3, description: 'Resolved', shortcode: 'RESOLVED' },
  // SPAM: { id: 4, description: 'Spam', shortcode: 'SPAM' },
  // BLOCKED: { id: 5, description: 'Blocked', shortcode: 'BLOCKED' },
});

export const transferTicketStatusIntent = {
  PENDINGAPPROVAL: 'warning',
  TRANSFERRED: 'info',
  REJECTED: 'danger',
  ACTIVE: 'success',
  RECALL: 'success',
};

export const TRANSFER_TICKET_STATUS = Object.freeze({
  ACTIVE: {
    id: 0,
    TransferredTicketsDescription: 'Active',
    TransferredTicketsShortCode: 'ACTIVE',
  },
  PENDINGAPPROVAL: {
    id: 1,
    TransferredTicketsDescription: 'Pending Approval',
    TransferredTicketsShortCode: 'PENDINGAPPROVAL',
  },
  TRANSFERRED: {
    id: 2,
    TransferredTicketsDescription: 'Transferred',
    TransferredTicketsShortCode: 'TRANSFERRED',
  },
  REJECTED: {
    id: 3,
    TransferredTicketsDescription: 'Declined',
    TransferredTicketsShortCode: 'REJECTED',
  },
  RECALL: {
    id: 4,
    TransferredTicketsDescription: 'Recalled',
    TransferredTicketsShortCode: 'RECALL',
  },
});

export const NOTIFICATION_TYPE_ID = Object.freeze({
  INVOICE: {
    id: 1,
    TransferredTicketsDescription: 'Invoice',
    TransferredTicketsShortCode: 'INVOICE',
  },
  PROMOTIONAVAILABLE: {
    id: 2,
    TransferredTicketsDescription: 'Available for promotion',
    TransferredTicketsShortCode: 'PROMOTIONAVAILABLE',
  },
  EVENTTICKETSALE: {
    id: 3,
    TransferredTicketsDescription: 'Event ticket sale',
    TransferredTicketsShortCode: 'EVENTTICKETSALE',
  },
  TRANSFERREDTICKETS: {
    id: 5,
    TransferredTicketsDescription: 'Transferred Tickets',
    TransferredTicketsShortCode: 'TRANSFERREDTICKETS',
  },
  TRANSFERREDTICKETSREJECT: {
    id: 9,
    TransferredTicketsDescription: 'Transferred Tickets Reject',
    TransferredTicketsShortCode: 'TRANSFERREDTICKETSREJECT',
  },

  TRANSFERREDTICKETSREJECTADMIN: {
    id: 10,
    TransferredTicketsDescription: 'TransferTickets Reject By Admin',
    TransferredTicketsShortCode: 'TRANSFERREDTICKETSREJECTADMIN',
  },
  TRANSFERREDTICKETSACCEPT: {
    id: 11,
    TransferredTicketsDescription: 'Transferred Tickets Accept',
    TransferredTicketsShortCode: 'TRANSFERREDTICKETSACCEPT',
  },
});

export const hostFinacialWalletFilter = [
  { label: 'Debit', value: 'DEBIT' },
  { label: 'Credit', value: 'CREDIT' },
];

export const DISPLAY_TYPE_OPTIONS = Object.freeze({
  ONE_DISPLAY: {
    label: 'One image is displayed for all events',
    value: false,
  },
  MULTIPLE_DISPLAY: {
    label: 'Multiple images are displayed for multiple events',
    value: true,
  },
});

export const walletPaymentMethod = Object.freeze({
  id: 5,
  name: 'TICKETSIRWALLET',
  descrption: 'TICKETSIRWALLET',
});
