const Endpoints = {
  VerifyUser: '/Account/VerifyUser',
  VerifySuperAffiliateUser: '/Affiliate/VerifySuperAffiliateUser',
  RegisterInterest: '/Transaction/RegisterWaitlist',
  GetStripeIntent: '/Payment/stripe/createCustomerAndSetupIntent',
  GetCards: '/Payment/stripe/stripeGetCardDetails',
  DeleteCard: '/Payment/stripe/stripeDeleteCard',
  LockTickets: '/Transaction/LockTicketsAsync',
  SubmitTransaction: '/Transaction/SubmitTransaction',
  VerifyPaystack: '/Payment/payStack/transactionVerify',
  AddEnquiry: '/Utility/AddEnquiry',
  AppleSignedInUserUpdate: '/Account/AppleSignedInUserUpdate',
  transactionVerify: '/Payment/transactionVerify',
  JoinTheEventForPromotionAsync: '/Promoter/JoinTheEventForPromotionAsync',
  SetPromoterCommissionOfTheEvent: '/Promoter/SetPromoterCommissionOfTheEvent',
  ActiveDeactivePromoterAsync: '/Promoter/ActiveDeactivePromoterAsync',
  ApproveRejectPromoterRequestAsync:
    '/Promoter/ApproveRejectPromoterRequestAsync',
  PromoteTheEventAsync: '/Promoter/PromoteTheEventAsync',
  EventsForPromotionList: '/Promoter/EventsForPromotionList',
  EventListingForHost: '/Promoter/EventListingForHost',
  StopPromotingTheEvent: '/Promoter/StopPromotingTheEvent',
  StarPromotingTheEvent: '/Promoter/StartPromotingTheEvent',
  PromoterOverview: '/Promoter/PromoterOverview',
  ExistingPromotersEntryForNewEvent:
    '/Promoter/ExistingPromotersEntryForNewEvent',
  UpdateInvoiceBankAccount: '/Invoice/UpdateInvoiceBankAccount',
  FollowTheHostAsync: '/Notification/FollowTheHostAsync',
  GetUserNotification: '/Notification/GetUserNotification',
  AcknowledgeNotification: '/Notification/AcknowledgeNotification',
  OnOffNotifications: '/Notification/OnOffNotifications',
  GetFollowedHostList: '/Notification/GetFollowedHostList',
  FollowedHostListAction: '/Notification/FollowedHostListAction',
  InviteToPromoteTheEventAsync: '/Promoter/InviteToPromoteTheEventAsync',
  GetUserNotificationFrequencyTypes:
    '/Notification/GetUserNotificationFrequencyTypes',
  SaveSalesEmailNotificationPreference:
    '/Notification/SaveSalesEmailNotificationPreference',
  PromoterRequestStatus: '/Notification/PromoterRequestStatus',
  ExportTicketALLOCATION: '/ExportReport/ExportTicketALLOCATION',
  DeleteEventProduct: '/Transaction/DeleteEventProduct',
  SaveEventBasicDetails: '/Event/SaveEventBasicDetails',
  addEventlocation: '/Event/addEventlocation',
  AddEventGeneral: '/Event/AddEventGeneral',
  AddEventDate: '/Event/AddEventDate',
  AddEventTickets: '/Event/AddEventTickets',
  AddEventSummary: '/Event/AddEventSummary',
  ResetPassword: '/Account/ResetPassword',
  DeleteUserEvent: '/Account/DeleteUserEvent',
  InsertUserEvent: '/Account/InsertUserEvent',
  UpdateUserProfile: '/Account/UpdateUserProfile',
  ChangeOldPassword: '/Account/ChangeOldPassword',
  AddHost: '/Account/AddHost',
  AddBank: '/Invoice/AddBank',
  DeleteUserBank: '/Account/DeleteUserBank',
  InitiateEmailVerification: '/Account/InitiateEmailVerification',
  VerifyResetPasswordToken: '/Account/VerifyResetPasswordToken',
  UserRegistration: '/Account/UserRegistration',
  VerifyEmail: '/Account/VerifyEmail',
  GoogleAuth: '/Account/GoogleAuth',
  AppleAuth: '/Account/AppleAuth',
  GetFurpBankProviders: '/Bank/GetBankProviders',
  FurpGetBank: '/Bank/GetBanks',
  FurpGetBeneficiaryRequirement: '/Bank/GetBeneficiaryRequirement',
  AddThirdPartyEventUserAsync: '/Event/AddThirdPartyEventUser',
  GetSiteMaintenanceStatus: '/Utility/GetSiteMaintenanceStatus',
  GetAvailableSeats: '/Allocation/GetAvailableSeats',
  ConfirmChangeSeats: '/Allocation/ConfirmChangeSeats',
  DeteleEventDressTerm: '/Event/DeteleEventDressTerm',
  UpdateEnquiry: '/Utility/UpdateEnquiry',
  ReplyEnquiry: '/Utility/ReplyEnquiry',
  TransferTickets: '/Transaction/TransferTickets',
  GetTicketToTransfer: '/Transaction/GetTicketToTransfer',
  TicketToRecallReject: '/Transaction/TicketToRecallReject',
  AcceptTickets: '/Transaction/AcceptTickets',
  UploadImageToS3: '/Utility/UploadImageToS3',
  UserRegistrationByAdmin:'/Account/UserRegistrationByAdmin'

};

export { Endpoints };
